const projectData = [
  {
    id: '1',
    title: "Balthazar's Bazaar",
    tag: 'React',
    status: 'Deployed MVP',
    quick: [
      '• A time saving tool for Dungeons and Dragons players',
      '• Users can log in with Google OAuth to upload items for the market',
      '• Firebase backend provides image storage and database',
    ],
    desc: [
      "This project is an heavily refactored evolution of a Udemy tutorial by Bradversy. Some changes I've made a listed below in blue.",
    ],
    icons: ['React', 'ReactRouter', 'Firebase', 'Sass'],
    img: '/images/bazaar_800.webp',
    git: 'https://github.com/DarranOS/dnd-saddlebag',
    deploy: 'https://dnd-saddlebag.vercel.app/',
    todo: ['Add ability to reserve items'],
    done: [
      'useContext employed for state',
      'Responsive visual redesign',
      'Refactored CSS into a SASS modules',
      'Restructured folders to MVC model',
    ],
  },
  {
    id: '2',
    title: 'This Site',
    tag: 'React',
    status: 'Operational site',
    quick: ['• A React site with my work', '• Check out behind the scenes'],
    desc: [
      'Feel free to have a snoop at the code that runs this site. The bright orange colour scheme was based on market research into upcoming trends for 2022.',
      'The site was made in React. Hosting is through Google Firebase',
    ],
    icons: ['React', 'Framer Motion', 'Firebase'],

    img: '/images/mysite_800.webp',
    url: '/projects-2',
    git: 'https://github.com/DarranOS/my-website',
    deploy: 'https://darranoshea.co.uk',
    improv: [],
    todo: ['Add gallery for illustration work', 'Improve slider for projects nav'],
    done: ['Lock scrolling when modal open'],
  },
  {
    id: '3',
    title: 'Apotheosis',
    tag: 'Next.js',
    status: 'Deployed MVP',
    quick: [
      '• Blog powered by Sanity CMS',
      '• Optimised up with Next.js',
      '• Styled with Tailwind CSS',
    ],

    desc: [
      'Just a nerdy blog to share rules changes for Dungeons and Dragons with my playgroup. Uses typescript for reduced bugs. Incremental static regeneration and page caching with Next.js',
    ],
    icons: ['Typescript', 'Next.js', 'Tailwind CSS', 'Sanity CMS'],

    img: '/images/apotheosis_800.webp',
    git: 'https://github.com/DarranOS/apotheosis',
    deploy: 'https://apotheosis.vercel.app/',
    improv: ['Used redux to manage state'],
    todo: ['Replace default favicon', 'Add login'],
    done: ['Make responsive', 'Correct Typos'],
  },

  {
    id: '4',
    title: 'Overleigh.co.uk',
    tag: 'Wordpress',
    status: 'Operational site',
    quick: [
      '• Live Wordpress site hosted on Google Cloud Platform',
      '• Stripe payments and Airbnb integration enabled',
    ],
    desc: [
      "I built and have maintained this Wordpress site since 2018. It's been through a few tweaks and redesigns in that time.",
      "The front end was built with Elementor Page builder. Deployed stack on Google Cloud platform through Bitnami by VMware. SLL certs by Let's Encrypt",
    ],
    icons: ['Wordpress', 'Elementor', 'Google Cloud Platform'],

    img: '/images/overleigh_800.webp',
    deploy: 'https://www.overleighatchester.co.uk/',
    improv: [],
    todo: ['Pending client request'],
    done: [],
  },

  // {
  //   id: '3',
  //   title: 'Facebook Clone',
  //   tag: 'Next.js',
  //   status: 'Deployed MVP',
  //   quick: ['Sign in with real Facebook credentials.', 'Post updates and images'],
  //   desc: [
  //     'I found a Youtube tutorial that for an intro to Next JS and Tailwind CSS. But it was 8 months old and Next JS and Firebase had breaking syntax changes.',
  //     'Rather than revert to an earlier version of those packages, I set myself the challenge to find the answer in the docs.',
  //     'It took a few hours of trawling, but it was very rewarding to figure out!',
  //   ],
  //   next: [],
  //   icons: ['React', 'Bootstrap', 'Tailwind CSS', 'Next.js', 'Firebase'],
  //   img: '/images/facebook_800.webp',
  //   url: '/projects-1',
  //   git: 'https://github.com/DarranOS/memory-card',
  //   deploy: 'https://memory-card-913u3b868-darranos.vercel.app/',
  //   improv: ['Used redux to manage state'],
  //   todo: ['Fix upload syntax'],
  //   done: ['Make responsive'],
  // },

  {
    id: '5',
    title: 'Memory Game',
    tag: 'React',
    status: 'Deployed MVP',
    quick: [
      '• A react game with Redux state management.',
      '• Animations with Framer Motion.',
    ],
    desc: ['Built as part of Odin project as an introduction to React.'],
    icons: ['React', 'Redux', 'Vercel', 'Styled Components'],
    img: '/images/bolas_800.webp',
    git: 'https://github.com/DarranOS/memory-card',
    deploy: 'https://memory-card-kb08o844q-darranos.vercel.app/',
    improv: ['Used redux to manage state'],
    todo: ['Improve UI for interaction'],
    done: ['Implement Redux', 'Add unmount spinning animations'],
  },

  // {
  //   id: '5',
  //   title: 'Borg Crush',
  //   tag: 'Javascript',
  //   status: 'Deployed MVP',
  //   quick: ['Sign in with Facebook credentials.', 'Post status updates and images'],
  //   desc: [
  //     'A simple Star Trek themed Candy Crush analogue game.',
  //     'Drag and drop three matching icons.',
  //   ],
  //   icons: ['React', 'Wordpress'],
  //   img: '/images/borg_crush_800.webp',
  //   url: '/projects-3',
  //   git: 'https://github.com/DarranOS/memory-card',
  //   deploy: 'https://memory-card-913u3b868-darranos.vercel.app/',
  //   improv: ['Used redux to manage state'],
  //   todo: [
  //     'Add mount/unmount animations',
  //     'Improve UI for interaction',
  //     'Replace default React Favicon and title',
  //   ],
  //   done: ['Make responsive', 'Correct Typos'],
  // },

  // {
  //   id: '6',
  //   title: 'Github Finder',
  //   tag: 'React',
  //   status: 'Deployed MVP',
  //   quick: ['Sign in with Facebook credentials.', 'Post status updates and images'],
  //   desc: [
  //     "This is a straight-forward tutorial from Udemy. I haven't changed anything yet.",
  //     "This project was a deeper dive into React's useContext API and",
  //   ],
  //   icons: ['React', 'Tailwind CSS', 'Postman'],

  //   img: '/images/bolas_800.webp',
  //   url: '/projects-5',
  //   git: 'https://github.com/DarranOS/memory-card',
  //   deploy: 'https://memory-card-913u3b868-darranos.vercel.app/',
  //   improv: ['Used redux to manage state'],
  //   todo: [
  //     'Add mount/unmount animations',
  //     'Add spinning card effect',
  //     'Improve UI for interaction',
  //     'Replace default React Favicon and title',
  //   ],
  //   done: ['Make responsive', 'Correct Typos'],
  // },

  // {
  //   id: '7',
  //   title: 'Library',
  //   tag: 'React',
  //   status: 'Deployed MVP',
  //   quick: ['Sign in with Facebook credentials.', 'Post status updates and images'],
  //   desc: [
  //     "This is a straight-forward tutorial from Udemy. I haven't changed anything yet.",
  //     'Dynamic',
  //     'Incremental static regeneration',
  //   ],
  //   icons: ['Next JS', 'Tailwind CSS', 'Sanity CMS', 'Github API', 'Axios', 'Postman'],

  //   img: '/images/library_800.webp',
  //   url: '/projects-5',
  //   git: 'https://github.com/DarranOS/apotheosis',
  //   deploy: 'https://apotheosis.vercel.app/',
  //   improv: ['Used redux to manage state'],
  //   todo: [
  //     'Add mount/unmount animations',
  //     'Add spinning card effect',
  //     'Improve UI for interaction',
  //     'Replace default React Favicon and title',
  //   ],
  //   done: ['Make responsive', 'Correct Typos'],
  // },

  // {
  //   id: '2',
  //   title: 'Battleships',
  //   tag: 'React',
  //   status: 'Deployed MP',
  //   quick: ['Sign in with Facebook credentials.', 'Post status updates and images'],
  //   desc: [
  //     'This started out as youtube tutorial for an introduction to Next JS and Tailwind CSS',
  //     'But it was about 7 months old and both Next JS and Firebase had was a great project for me because X, Y and Z',
  //     'THis was a great project for me because X, Y and Z',
  //     'THis was a great project for me because X, Y and Z',
  //   ],
  //   icons: ['Sass', 'React'],
  //   img: '/images/library_800.webp',
  //   url: '/projects-2',
  //   git: 'https://github.com/DarranOS/memory-card',
  //   deploy: 'https://memory-card-913u3b868-darranos.vercel.app/',
  //   improv: ['Used redux to manage state'],
  //   todo: [
  //     'Add mount/unmount animations',
  //     'Add spinning card effect',
  //     'Improve UI for interaction',
  //     'Replace default React Favicon and title',
  //   ],
  //   done: ['Make responsive', 'Correct Typos'],
  // },

  {
    id: '9',
    title: 'Baytree.com',
    tag: 'Wordpress',
    status: 'Operational site',
    quick: ['• Live Wordpress site hosted on Google Cloud Platform'],
    desc: [
      "I built and have maintained this Wordpress site since 2018. It's been through a few tweaks and redesigns in that time.",
      "The front end was built with Elementor Page builder. Deployed stack on Google Cloud platform through Bitnami by VMware. SLL certs by Let's Encrypt",
    ],
    icons: ['Wordpress', 'Elementor', 'Google Cloud Platform'],

    img: '/images/baytree_800.webp',
    // git: 'https://www.overleighatchester.co.uk',
    deploy: 'https://baytreelodge.com/',
    improv: [],
    todo: ['Pending client request'],
    done: ['Automate Cron job for SSL certs'],
  },

  {
    id: '10',
    title: 'the-suburbs.co.uk',
    tag: 'Wordpress',
    status: 'Operational site',
    quick: [
      '• Live ecommerce Wordpress site hosted on Google Cloud Platform',
      '• Stripe payments and woocommerce integration',
    ],
    desc: [
      'The front end was built with Elementor Page builder. Deployed stack on Google Cloud platform through Bitnami by VMware.',
      'Subsequently handed-off to an agency.',
    ],
    icons: ['Wordpress', 'Elementor', 'Google Cloud Platform'],

    img: '/images/suburbs_800.webp',
    url: '/projects-2',
    // git: 'https://www.overleighatchester.co.uk',
    deploy: 'https://the-suburbs.co.uk/',
    improv: [],
    todo: ['NA'],
    done: ['Hosting and maintenance tranferred to agency'],
  },
  // {
  //   id: '11',
  //   title: 'Cocktail Menu Design',
  //   tag: 'Illustration',
  //   status: 'Operational site',
  //   quick: ['• Illustration work for a modern, stylish cocktail menu design.'],
  //   desc: [
  //     'The front end was built with Elementor Page builder.',
  //     'Deployed stack on Google Cloud platform through Bitnami by VMware',
  //     'Subsequently handed-off to an agency.',
  //   ],
  //   icons: ['Photoshop', 'Illustration'],

  //   img: '/images/cocktails2_800.webp',
  //   url: '/projects-2',
  //   // git: 'https://www.overleighatchester.co.uk',

  //   improv: [],

  //   done: [],
  //   link: 'illustration',
  // },
]

export default projectData
